/*! themes/_main.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Main theme variables and styles (Main theme is used in "Startup Kit", in "Landing kit v1" and in all components pages)
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Theme gradient
2. Box shadows
3. Pageloader
4. Theme specific Hero
=============================================================================
***/

/* ==========================================================================
0. Theme color variables
========================================================================== */
$primary: #95D705;
$secondary: #7DB201;
/*$accent: #313131;*/
$accent: #363d43; //RAL 7016

// Set your brand colors
$mine-shaft: #313131;
$beige-light: #D0D1CD;
$beige-lighter: #F8F8F8;
$light-green-grey: rgb(156, 190, 75);

/* ==========================================================================
1. Theme gradient
========================================================================== */
$webkit-hero-gradient: linear-gradient(to right, $secondary, $accent);
$hero-gradient: linear-gradient(to top, $secondary, $accent);

/* ==========================================================================
2. Box shadows
========================================================================== */
//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(79, 193, 234, 0.42);
$primary-shadow-to: rgba(79, 193, 234, 0.2);
$primary-box-shadow: 0 14px 26px -12px $primary-shadow-from,
0 4px 23px 0px $base-shadow,
0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(103, 58, 183, 0.42);
$secondary-shadow-to: rgba(103, 58, 183, 0.2);
$secondary-box-shadow: 0 14px 26px -12px $secondary-shadow-from,
0 4px 23px 0px $base-shadow,
0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(127, 0, 255, 0.42);
$accent-shadow-to: rgba(127, 0, 255, 0.2);
$accent-box-shadow: 0 14px 26px -12px $accent-shadow-from,
0 4px 23px 0px $base-shadow,
0 8px 10px -5px $accent-shadow-to !important;


/* ==========================================================================
3. Pageloader
========================================================================== */
.pageloader {
  background-color: $secondary;
  background: $hero-gradient !important;
}

/* ==========================================================================
4. Theme specific Hero
========================================================================== */
.hero,
.section {
  &.is-theme-secondary {
    background-color: $secondary;
    // background: $webkit-hero-gradient;
    // background: $hero-gradient;

    .title {
      color: $white;
    }

    .subtitle {
      color: $white;
    }
  }
}

figure {
  figcaption {
    text-align: right;
    font-family: 'Open Sans Bold', sans-serif;
    font-weight: 600;
    font-size: 0.7rem;


  }
}

abbr[title],
acronym[title] {
  border-bottom: 1px dotted;
}

/*==========================================================================
4. Uniform Plugin specific 
========================================================================== */
.uniform__potty {
  position: absolute;
  left: -9999px;
}

.recent-post.slick-slide.slick-current {
  background-color: $beige-light;
}

.slide-wrapper {
    position: relative;
    display: inline-block;
}

.mycaption {
    position: absolute;
    left: 3%;
    bottom: 4%;
    width: 60%;
    z-index: 99;
    padding: 25px;
    background:rgba($accent,0.8);// rgba(0,0,0,0.8);
    transform: translateY(0);
    transition: transform 0.4s;
    color:white;
    line-height: 1.25;;
    // span { 
    //   font-size: 0.9rem;
    // }
    .post-title{
      padding-bottom: 1rem
    }
    .post-subtitle{
      color:$secondary;
    }
    &.has-text-left:hover {
      background:rgba($accent,1.0);
      cursor: pointer;
    }
}

.flex-card {

  .card-panel {

    .post-subtitle {
      color: $secondary;
    }

    // .post-title {

    // }
  }
}

.media-content .title {
  min-height: 2.25em;
}

.media-card.agilox-shortnews {
  max-height: 100px;
  min-height: 100px;

    //image
  .media-card-image {
      height: 100% !important;
      min-height: 170px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
  }
   //content
   .mcard-content {
    padding: 5px;
    
    .mcard-title {
      font-family: 'Nexa Bold', sans-serif;
      font-weight: 600;
      line-height: 1.2 !important;
      font-size: 16px !important;

      color: $secondary;

      a {
        color: $secondary;
      }
    }
  }
 
}

.h-70 {
      height: 70% !important;
}


/* ==========================================================================
5. Parallax counters
========================================================================== */

//Parallax counters icons
.parallax-counter.is-accent i {
  color: $accent !important;
}

.section {
  //Section colors
  &.section-mine-shaft-grey {
      background-color: rgba(88, 89, 92, 1.00)
  }
}

/* ==========================================================================
8. Latest posts
========================================================================== */
.latest-posts {
  .card {
      //border-radius: 5px;
      box-shadow: 0 3px 10px 4px rgba(0,0,0,.04);
      border: 1px solid $fade-grey;
      // .card-image figure img {
      //     border-top-left-radius: 5px;
      //     border-top-right-radius: 5px;
      // }
      figure.author {
          position: absolute;
          bottom: -24px;
          right: 20px;
          .img-circle {
              border: 2px solid $white;
              border-radius: 50%;
          }
      }
      .media {
          margin: 0;
      }
      .title {
          font-family: 'Nexa Bold', sans-serif;
          font-weight: 600;
      }
      .timestamp {
          font-size: 85%;
          color: $title-grey;
          padding: 5px 0;
          i {
              margin-right: 7px;
          }
      }
      .post-exerpt {
          padding: 10px 0 20px 0;
      }
  }
  .button-cta.primary-btn.btn-outlined.is-link.color-primary {
    background: white !important;

    &:hover {
      color: white !important;
      background: $primary !important;
      i {
        color: white !important;
        transform: translate(15px,0);
      }
    }

   
  }

}

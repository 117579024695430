/*! _startup.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Startup Kit SCSS file
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Wallop fullscreen slider
1. Section title
2. Icon box
3. Images
4. Main Post (News)
5. Side Post (News)
6. Pulsating dots and custom tooltips
7. Section hero background image (System Agilox)

5. Product page
6. Cards
7. Team section
8. Parallax counters
9. Pricing
=============================================================================
***/

/* ==========================================================================
0. Wallop fullscreen slider
========================================================================== */
.Wallop {
    top: -75px;
    .Wallop-list {
        width: 100%;
        height: 100%;
    }
    .Wallop-item {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .Wallop-buttonPrevious, .Wallop-buttonNext {
        position: absolute;
        top: 48%;
        background: none;
        border: none;
        cursor: pointer;
        z-index: 6;
        &:hover {
            i {
                opacity: 0.8;
                transform: scale(1.05);
            }
        }
        i {
            font-size: 3.5rem;
            color: $white;
            transition: all .4s;
        }
        &:active, &:focus {
            outline: none !important,
        }
    }
    .Wallop-buttonPrevious {
        left: 20px;
    }
    .Wallop-buttonNext {
        right: 20px;
    }
    .Wallop-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.3);
        z-index: 1;
    }

    /* Pagination */
    .Wallop-pagination {
        text-align: center;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 40px;
        z-index: 100;
    }

    .Wallop-dot {
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        padding: 0;
        margin: 5px;
        background-color: #ccc;
        cursor: pointer;
        -webkit-appearance: none;  
        &:active, &:focus {
            outline:  none !important;
        }
    }

    .Wallop-dot--current {
        background-color: $primary;
    }
    //Slider caption
    .Wallop-caption-wrapper {
        align-items: center;
        min-height: 100vh;
        position: relative;
        z-index: 99;
        .container {
            position: relative;
            min-height: 500px;
            height: 100vh;
            display: flex;
            .caption-inner {
                /*position: absolute;
                top: calc(50% - 135px);
                left: 20px;
                max-width: 40%;*/
                h1 {
                    font-family: 'Open Sans Bold', sans-serif;
                    font-weight: 700;
                    font-size: 3.8rem;
                    line-height: 3.8rem;
                    color: $white;
                }
                .caption-divider {
                    width: 30%;
                    height: 3px;
                    background: $white;
                    margin: 10px 0;
                }
                .caption-text {
                    //max-width: 90%;
                    //padding: 10px 0;
                    color: $white;
                    font-size: 1.05rem;
                    font-weight: 400;
                    .action {
                        padding: 20px 0;
                    }
                }
            }
        }
    }
}

.hero-foot.is-pulled {
    margin-top: -65px;
}

//Wallop slider media query (mobile)
@media (max-width: 767px) {
    .caption-inner {
        max-width: 100% !important;
        top: 30% !important;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        .caption-divider {
            height: 3px !important;
            margin: 10px auto !important;
        }
        h1 {
            font-size: 2.8rem !important;
        }
        .caption-text span {
            margin: 0 20px;
            display: block;
        }
    }
}

//Wallop slider media query (tablet in portrait mode)
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .caption-inner {
        text-align: center;
        position: relative;
        top: 40vh !important;
        .caption-divider {
            margin: 10px auto !important;
            height: 3px !important;
        }
        .caption-text {
            padding: 0 15%;
        }
    }
    .is-tablet-padded {
        padding: 0 20% !important;
    }
}


/* ==========================================================================
1. Section title
========================================================================== */


.service-box {
    .service-title {
        font-family: 'Open Sans Bold', sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        color: $blue-grey;
    }
    p {
        padding: 5px 0;
    }
    .action {
        padding: 10px 0;
        a {
            border-width: 2px;
            font-weight: 600;
        }
    }
}

.centered-title {
    text-align: center;
    h2 {
        font-family: 'Open Sans Bold', sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        color: $blue-grey;
    }
    .subheading {
        font-family: "Open Sans Light", sans-serif;
        font-weight: 600;
        max-width: 70vh;
        margin: 0 auto;
        padding: 10px 20px;
        font-size: 18px;
        color: $title-grey;
    }
    .title-divider {
        margin: 10px auto;
    }
    &.is-inverted {
        h2, .subheading {
            color: $white;
        }
    }
}

.title.main-title {
    font-family: 'Open Sans Bold', sans-serif;
    margin-bottom: 0;
    padding: 20px 0;
}

.section-title-wrapper {
    .section-title {
        font-family: 'Open Sans Bold', sans-serif;
        margin-bottom: 0;
        padding: 10px 0;
    }
    .subtitle {
        font-family: 'Open Sans Light', sans-serif;
        font-size: 1.2rem;
        line-height: 30px;
        font-weight: 400;
        padding: 20px 25%;
        color: $title-grey;
    }
}

.section-subtitle, .title.feature-title {
    font-family: 'Open Sans Bold', sans-serif !important;
    font-weight: 500 !important;
    font-size: 2rem !important;
    margin-bottom: 0 !important;
    padding: 5px 0 !important;
}

@media (max-width: 767px) {
    .section-title-wrapper {
        .subtitle {
            font-size: 1.1rem;
            line-height: 22px;
            padding: 30px 0;
        }
    }
}

//(Portrait mode)
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .section-title-wrapper {
        .subtitle {
            padding: 20px 10% !important;
        }
    }
}

//Parallax title
.parallax-title {
    font-family: 'Open Sans Bold', sans-serif;
    font-weight: 600 !important;
    font-size: 2.4rem !important;
    margin-bottom: 0 !important;
    padding: 10px 0;
}

/* ==========================================================================
2. Icon box
========================================================================== */

.startup-icon-box {
    padding: 20px 0;
    i {
        font-size: 3.8rem;
        font-weight: normal;
        color: $primary;
    }
    .box-title {
        font-family: 'Open Sans Light', sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        color: $blue-grey;
        padding: 5px 0;
    }
    .box-content {
        color: $muted-grey;
    }
}

/* ==========================================================================
3. Images
========================================================================== */

//Featured images
.featured-svg {
    max-width: 450px;
    z-index: -1;
}

@media (max-width: 767px) {
    .featured-svg {
        max-width: 100% !important;
    }
}

/* ==========================================================================
4. Main post
========================================================================== */

//Features post
.main-post {
    //image
    .post-image {
        position: relative;
        border-radius: 6px;
        img {
            display: block;
            // border-radius: 6px;
            // box-shadow: $secondary-box-shadow;
        }
        //overlay
        .image-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($secondary, 0.6);
            border-radius: 6px;
            transition: all .3s;
        }
        &:hover {
            .image-overlay {
                background: rgba($secondary, 0.4);
            }
        }
    }
    //content
    .post-content {
        padding: 20px;
        h3 {
            font-family: 'Open Sans Bold', sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            color: $secondary;
            margin: 10px 0 0 0;
            a {
                color: $secondary;
                transition: all .3s;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        //timestamp
        .time {
            display: block;
            font-size: 1.1rem;
            font-weight: 500;
            color: $muted-grey;
            margin-bottom: 16px;
        }
        p {
            font-size: 1.1rem;
            color: $muted-grey;
        }
    }
}

/* ==========================================================================
5. Side posts
========================================================================== */

.side-post {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
    //image
    .post-image {
        position: relative;
        img {
            display: block;
            width: 100px;
            min-width: 100px;
            height: 100px;
            min-height: 100px;
            object-fit: cover;
            // border-radius: 6px;
            // box-shadow: $secondary-box-shadow;
        }
        //overlay
        .image-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($secondary, 0.6);
            border-radius: 6px;
            transition: all .3s;
        }
        &:hover {
            .image-overlay {
                background: rgba($secondary, 0.4);
            }
        }
    }
    //content
    .post-content {
        flex-grow: 2;
        padding: 0 20px;
        h3 {
            font-family: 'Open Sans Bold', sans-serif;
            font-size: 1.2rem;
            font-weight: 500;
            color: $secondary;
            margin-bottom: 6px;
            a {
                color: $secondary;
                transition: all .3s;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        p {
            font-size: 1rem;
            color: $muted-grey;
        }
    }
}

/* ==========================================================================
6. Pulsating dots and custom tooltips
========================================================================== */

//Pulsating dots on laptop mockup
.mockup-dots {
    .dot {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: block;
        background-color: $primary;
        z-index: 5;
        &:before {
            position: absolute;
            background: $primary;
            content: '';
            top: 0;
            left: 0;
            border: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            animation: dotPulse 2s ease-out infinite;
        }
        &.first {
            top: 47%;
            left: 20%;
            &:hover + .dot-tip.tip-first {
                display: block;
            }
        }
        &.second {
            top: 65%;
            left: 20%;
            &:hover + .dot-tip.tip-second {
                display: block;
            }
        }
        &.third {
            top: 55%;
            right: 20%;
            &:hover + .dot-tip.tip-third {
                display: block;
            }
        }
    }
    //Custom tooltips
    .dot-tip {
        padding: 15px 20px;
        background-color: $primary;
        color: $white;
        border-radius: 10px 10px 0 10px;
        font-size: 13px;
        display:none;
        z-index: 4;
        &.tip-first {
            position: absolute;
            top: 42%;
            left: 0;
        }
        &.tip-second {
            position: absolute;
            top: 60%;
            left: 0;
        }
        &.tip-third {
            position: absolute;
            top: 50%;
            right: 24%;
        }
    }
}

//Read more button
.read-more {
    display: flex;
    align-items: center;
    padding: 16px 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: $secondary;
    &.low-padding {
        padding: 8px 0;
        font-size: 1rem;
        i {
            font-size: 1.2rem;
        }
    }
    i {
        position: relative;
        top: 2px;
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 6px;
    }
}

/* ==========================================================================
 7. Section hero background image (System Agilox)
========================================================================== */

.section-system-bg {
    @extend .section-light-grey;
    // background: url('/assets/images/bg/agilox-bg-lines-hd400.png');
    // background-position: right bottom;
    // background-repeat: no-repeat;
}

/* ==========================================================================
5. Product page
========================================================================== */

//Hero email signup
.signup-block {
    .product-hero {
        background-position-x: center !important;
    }
    .control {
        position: relative;
        width: 80%;
        .input {
            height: 50px;
            padding-left: 10px;
            padding-right: 140px;
            font-size: 1rem;
            &::placeholder {
                color: $placeholder;
            }
        }
        button {
            position: absolute;
            top: 4px;
            right: 5px;
            height: 42px;
            width: 120px;
            font-weight: 600;
        }
    }
}

//Hero email signup media query (mobile and tablet)
@media (max-width: 768px) {
    .signup-column {
        margin-top: 0 !important;
        text-align: center !important;
    }
    .signup-block {
        .control {
            width: 100%;
        }
    }
}


/* ==========================================================================
6. Cards
========================================================================== */

//Feature cards
.feature-card.is-startup {
    padding: 20px;
    img {
        margin: 0;
    }
}

//Integrations cards
.integration-cards {
    .feature-card {
        padding: 50px 20px;
        margin-bottom: 20px;
    }
    .brand-logo {
        padding: 10px 0;
        img {
            max-height: 60px;
            max-width: 120px;
        }
    }
    .card-title {
        padding: 10px 0;
    }
    .card-feature-description {
        padding: 0 !important;
    }
    a {
        margin: 20px 0;
    }
}

//CTA
.cta-text {
    font-family: 'Open Sans Bold', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}

//Values cards
.values-cards {
    .feature-card {
        height: 310px;
    }
    .card-feature-description {
        font-size: 95%;
    }
    .card-icon {
        padding-top: 50px;
        i {
            font-size: 5rem;
            color: $primary;
        }
    }
    &.is-minimal {
        padding: 0 8%;
        i {
            color: $blue-grey;
            font-size: 4.5rem;
        }
        .feature-card:hover {
            i {
                color: $primary;
            }
        }
    }
}

//Values cards media query (mobile)
@media (max-width: 768px) {
    .values-cards.is-minimal {
        padding: 0 !important;
    }
}

//Startup contact page cards
.contact-card {
    display: flex;
    align-content: center;
    align-items: center;
    .icon i {
        font-size: 26px;
        color: $accent;
        position: relative;
    }
    .contact-info {
        margin: 0 30px;
        .contact-name {
            color: $placeholder;
        }
        .contact-details {
            .details-text {
                font-size: 16px;
                color: $blue-grey !important;
            }
        }
    }
}

/* ==========================================================================
7. Team section
========================================================================== */

//Team section
.modern-team.startup-team {
    .modern-team-item {
        padding: 0 5px;
        width: 33%;
        .item-wrapper {
            border: 1px solid $fade-grey;
            box-shadow: 0 3px 10px 4px rgba(0,0,0,.04);

            &:hover {
                border: 0px;
                box-shadow: none;
            } 
        }
        .item-img {
            &:before {
                content: "";
                background-color: rgba(183, 201, 211, .8);
            }
        }
        img {
            border-radius: 10px;
        }
        &:hover .item-img {
            border-radius: 10px;
        }
        a {
            i {
                color: $primary;
            }
            &:hover i {
                color: $blue-grey;
            }
        }
    }
}

//Team section media query (mobile only)
@media (max-width: 767px) {
    .modern-team-item {
        width: auto !important;
    }
    #google-map {
        min-height: 50vh;
    }
}

/* ==========================================================================
8. Parallax counters
========================================================================== */

//Parallax counters icons
.parallax-counter.is-primary i {
    color: $primary !important;
}

//Parallax counters icons
.parallax-counter.is-secondary i {
    color: $secondary !important;
}

.parallax-counter.is-secondary {
    .counter-number {
        color: $accent !important;
    }
    .counter-text {
        color: $mine-shaft !important;
    }    
}
/* ==========================================================================
9. Pricing
========================================================================== */

.pricing-plan {
    .plan-header {
        text-transform: uppercase;
        font-weight: 400 !important;
        font-family: 'Open Sans Bold', sans-serif;
        color: $muted-grey;
    }
}

/* ==========================================================================
10. Customers client section
========================================================================== */

.grid-clients {
    .client {
        max-height: 70px;
    }
    //3 columns grid
    &.five-grid {

        a {
            display: flex;
            justify-content: center;
            overflow: auto;      /*ah1-2 */          
            img {
                flex-shrink: 0;  /*ah1-1: lösung von hier: https://codepen.io/noahblon/post/practical-guide-to-flexbox-dont-forget-about-flex-shrink*/
                align-self: center; 
                /* ah2: erzerrtes image: https://stackoverflow.com/questions/37609642/why-does-flexbox-stretch-my-image
                   und https://developer.mozilla.org/en-US/docs/Web/CSS/align-self */
                
                filter: grayscale(100%); 
                -webkit-filter:  saturate(0.4) grayscale(100%) contrast(100%);
                opacity: .3;
            }
            img:hover {
                -webkit-filter: grayscale(0);
                filter: none;
                opacity: 1.0;
              }
        }

    }
}


// ah: copied from agency blog part _pages-agency.scss


/* ==========================================================================
14. Blog main page
========================================================================== */

.blog-hero {
    padding: 8rem 1.5rem;
}

.flex-card.is-post {
    border-radius: 6px;
    &.is-pulled-top {
        margin-top: -280px;
    }
    // &.light-bordered { /* //21.01.2019-ah test concept*/ 
    //     border-left: 4px solid $primary !important; 
    // }
    .header {
        min-height: 170px;
        width: 100%;
        background: $primary;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        position: relative;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        h2.post-title {
            font-family: 'Open Sans Bold', sans-serif;
            position: relative;
            color: $white;
            font-size: 1.6rem;
            font-weight: 500;
            padding: 0 20px;
            z-index: 1;
        }
        h4.post-subtitle {
            position: relative;
            color: $white;
            font-family: 'Open Sans Light', sans-serif;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 10px 20px;
            z-index: 1;            
        }
        h4.post-subtitle.is-detail {
            font-size: 0.9rem;
        }
        .author-avatar {
            position: absolute;
            right: 60px;
            bottom: -50px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 5px solid $white;
            z-index: 1;
            display: block;
            img {
                border-radius: 50%;
                position: relative;
                left: -0.5px;
                transform: scale(1.02);
            }
        }
        .header-overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $mine-shaft;
            opacity: 0.35;
            z-index: 0;
        }
        .headerdetail-overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $secondary;
            opacity: 0.45;
            z-index: 0;
        }
    }
    .post-body {
        padding: 20px;
        div span {
            font-size: 90%;
            color: $muted-grey;
        }
        div a.author-name {
            font-family: 'Open Sans Light', sans-serif;
            color: $blue-grey;
            &:hover {
                color: $primary;
            }
        }
        p {
            padding: 10px 0;
            color: $blue-grey;
        }
        hr {
            background-color: $fade-grey;
        }
        h2.post-title {
            font-family: 'Open Sans Bold', sans-serif;
            position: relative;
            color: $secondary;
            font-size: 1.0 rem;
            font-weight: 500;
            
            z-index: 1;
        }
        a {
            color: $primary;
            &.button {
                color: $muted-grey;
                font-weight: 500;
                font-family: 'Open Sans Bold', sans-serif;
                &:hover {
                    color: $blue-grey !important;
                }
            }
            &:hover {
                opacity: 0.7;
            }
        }
        small {
            color: $muted-grey;
            font-size: 0.9rem;
        }
        .footer-details {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .likes-count, .comments-count {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                i {
                    font-size: 1.5rem;
                    font-weight: 900;
                    //position: relative;
                    //top: 4px;
                    color: $title-grey;
                }
                .stat {
                    font-size: 90%;
                    color: $title-grey;
                    font-weight: 500;
                    padding: 0 4px
                }
            }
            .likes-count {
                margin-right: 10px;
            }
            .comments-count {
                margin: 0 10px;
            }
        }
    }
}

//Like button in blog pages
.fab-btn {
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    padding: 16px;
    border: none;
    border-radius: 50%;
    color: $white;
    transition: all 0.2s ease;
    cursor: pointer;
    z-index: 1;
    &:active, &:focus {
        outline: none !important;
    }
    &.mini {
        width: 48px;
        height: 48px;
        padding: 12px;
        transform: rotate(-600deg);
        background: $primary;
    }
    &.like { 
        background: $title-grey !important;
        transform: rotate(0deg);
        position: absolute;
        right: 28%;
        bottom: -22px;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
        i.unliked {
            display: block;
            color: $white;
            position: relative;
            z-index: 1;
        }
        i.liked {
            display: none;
            color: $white;
            position: relative;
            z-index: 1;
        }
        &.is-active {
            .like-overlay {
                transform: scale(1);
            }
            i.unliked {
                display: none;
            }
            i.liked {
                display: block;
            }
        }
        &.is-single {
            bottom: -34px;
        }
    }
    .like-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        .like-overlay {
            position: absolute;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            top: -36px;
            left: -24px;
            background: $red;
            transform: scale(0);
            transition: transform 0.4s;
            z-index: 0;
        }
    }
}


//Blog page media query (mobile)
@media (max-width: 768px) {
    .blog-section {
        padding: 5px !important;
    }
    .flex-card.is-post {
        &.is-pulled-top {
            margin-top: -80px;
        }
        .header .author-avatar {
            right: 20px !important;
            bottom: -30px !important;
            width: 60px !important;
            height: 60px !important;
            border: 3px solid $white !important;
            img {
                left: 0.5px;
                transform: scale(1);
            }
        }
    }
}

/* ==========================================================================
15. Blog post
========================================================================== */

//Blog sidebar search input
.control.is-blog-search {
    position: relative;
    margin-bottom: 20px;
    input {
        height: 60px;
        width: 100%;
        border: none;
        padding-left: 45px;
        border: 1px solid $fade-grey;
        transition: all 0.5s;
        &:focus {
            box-shadow: inset rgba(143,160,241,0.2) 0 0 0 1px, rgba(213,220,247,0.59) 0 10px 20px;
            border: 1px solid $primary;
            border-radius: 5px;
            + i {
                color: $primary;
            }
        }
        &:focus, &:active {
            outline: none;
        }
    }
    i {
        position: absolute;
        left: 15px;
        top: 22px;
        color: $placeholder;
        font-size: 20px;
        transition: color 0.5s;
    }
}

//Blog single post
.flex-card.is-full-post {
    border-radius: 6px;
    padding: 60px;
    &.has-sidebar {
        padding: 40px;
    }
    &.is-pulled-top {
        margin-top: -180px;
    }
    .post-meta {
        display: flex;
        justify-content: flex-start;
        padding: 10px 30px;
        margin: 0;
        border-bottom: 1px solid $fade-grey;
        img {
            width: 55px;
            height: 55px;
            border-radius: 50%;
        }
        .title-block {
            position: relative;
            padding: 0 20px;
            h2, h4 {
                margin-bottom: 0;
            }
            h2 {
                font-family: 'Open Sans Bold', sans-serif;
                font-weight: 700; 
            }
            h4 {
                font-family: 'Open Sans Light', sans-serif;
                color: $muted-grey;
                padding: 10px 0;
            }
            .like {
                right: 20px;
                &.is-full {
                    bottom: -32px;
                }
            }
        }
    }
    .post-body {
        padding: 10px 30px;
        .author-name {
            padding: 10px 0 5px 0;
            font-size: 90%;
            color: $title-grey;
            b a {
                color: $blue-grey;
                &:hover {
                    color: $primary;
                }
            }
        }
        p {
            font-size: 16px;
        }
        .timestamp {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 90%;
            color: $title-grey;
            padding: 5px 0 10px 0;
            i {
                font-size: 16px;
                color: $primary;
                margin-right: 5px;
            }
        }
        h5 {
            font-family: 'Open Sans Bold', sans-serif;
            font-size: 1.3rem;
            font-weight: 500;
            color: $blue-grey;
            padding: 10px 0;
            margin-bottom: 0;
        }
        .post-image {
            margin: 10px 0;
            border-radius: 10px;

        }
        hr {
            background-color: $fade-grey;
        }
        .share-post {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .share-text {
                font-weight: bold;
                color: $blue-grey;
            }
            .sharing-options {
                i {
                    font-size: 1.4rem;
                    margin: 0 5px;
                    cursor: pointer;
                    color: $title-grey;
                    &:hover {
                        &:first-child  {
                            color: $primary;
                        }
                        &:nth-child(2) {
                            color: $facebook;
                        }
                        &:nth-child(3) {
                            color: $twitter;
                        }
                        &:nth-child(4) {
                            color: $linkedin;
                        }
                        &:nth-child(5) {
                            color: $google-plus;
                        }
                        &:nth-child(6) {
                            color: $reddit;
                        }
                        &:nth-child(7) {
                            color: $tumblr;
                        }
                    }
                }
            }
        }
    }
}

//Comment count section
.comment-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-radius: 6px;
    i {
        font-size: 2rem;
        font-weight: 500;
        color: $title-grey;
    }
    .text {
        font-size: 1.1rem;
        font-weight: 400;
        color: $title-grey;
        margin-left: 20px;
        span.count-number {
            font-size: 1.1rem;
        }
    }
    button {
        margin-left: auto;
        color: $muted-grey !important;
    }
}

//Write comment card
.compose-card {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: unset;
    .control-wrapper {
        width: 100%;
        padding-left: 20px;
        .textarea-button {
            background: $fade-grey;
        }
    }
}

//Comments list
.comments-list {
    border-radius: 6px;
    padding: 40px;
    .media {
        margin: 0 !important;
        padding: 10px 0 !important;
        border: none !important;
        .media-left img {
            border-radius: 50%;
        }
        small {
            color: $title-grey;
            .reply a {
                font-size: 95%;
                padding: 0 20px;
            }
        }
        small a {
            color: $title-grey;
            font-size: 16px;
            i {
                vertical-align: middle;
                font-size: 16px;
                margin: 0 5px;
                &:hover {
                    color: $red !important;
                }
            }
            &:hover {
                color: $primary;
                i {
                    color: $red;
                }
            }
        }
        .count {
            color: $title-grey;
            font-weight: 500;
        }
        .timestamp {
            display: block;
            font-size: 90%;
            color: $title-grey;
        }
        strong {
            font-family: 'Open Sans Bold';
            font-weight: 600 !important;
        }
    }
}

.content p:not(:last-child) {
    margin-bottom: 0 !important;
}

//agilox flex-card link box
.flex-card .card-body {
    min-height: 16em;
}

//Blog sidebar widgets
.flex-card {
    .card-header {
        font-family: 'Open Sans Bold', sans-serif;
        padding: 20px;
        font-size: 1.2rem;
        font-weight: 500;
        color: $blue-grey;
    }
    .card-panel {
        //Recent posts
        .recent-posts {
            .recent-post {
                padding: 10px 20px;
                border-bottom: 1px solid $fade-grey;
                .post-title {
                    opacity: 0.999; /*aus vorlage bereits leer, test von mir*/
                }
                .post-title a {
                    color: $white; //$blue-grey;
                    font-weight: 600;
                    &:hover {
                        color: $primary;
                    }
                }
                .post-meta {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                    }
                }
                span {
                    display: block;
                    font-size: 90%;
                    color: $title-grey;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        //Latest comments
        .latest-comments {
            .latest-comment {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 16px 20px;
                border-bottom: 1px solid $fade-grey;
                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .comment-info {
                    .name a {
                        color: $blue-grey;
                        font-weight: 600;
                        &:hover {
                            color: $primary;
                        }
                    }
                    .post-ref a {
                        font-size: 90%;
                        color: $title-grey;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
        //Archives and categories
        .archives, .post-categories {
            .archived-month, .post-category {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 20px;
                border-bottom: 1px solid $fade-grey;
                span a {
                    color: $blue-grey;
                    font-weight: 600;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        //Tags
        .tag {
            margin: 5px;
        }
    }
}

//Flex title
.title.flex-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span.material-icons {
        font-size: 2.4rem;
        padding: 8px;
        border: 2px solid $white;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            border: 2px solid $primary;
            background: $primary;
            color: $white;
        }
    }
}

//Single post media query (mobile)
@media (max-width: 768px) {
    .flex-card.is-full-post {
        &.is-pulled-top {
            margin-top: -80px;
        }
        .post-body {
            padding: 20px !important;
        }
    }
    .title.flex-title {
        justify-content: center;
    }
}

/* ==========================================================================
16. Media queries
========================================================================== */

//Mobile
@media (max-width: 767px) {
    .title.main-title {
        font-size: 3rem !important;
    }
    .flex-card {
        &.is-post {
            h2 {
                font-size: 1.3rem !important;
            }
        }
        &.is-full-post {
            padding: 10px !important;
            .post-body {
                padding: 20px 10px !important;
            }
        }
    }
    .post-meta {
        margin: 0 !important;
        padding: 10px !important;
        .title-block {
            padding: 0 !important;
            h2 {
                font-size: 1.5em !important;
            }
        }
    }
    .post-body {
        padding: 20px 10px !important;
    }
    .compose-card {
        .control-wrapper {
            padding: 0;
        }
    }
}

//Tablet orientation portrait
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .portfolio-item {
        img {
            width: 100%;
        }
    }
    .agency-icon-box, .service-box {
        text-align: center;
        .title-divider {
            margin: 0 auto;
        }
    }
    .flex-card.is-post, .flex-card.is-full-post {
        padding: 40px;
        .post-meta {
            padding: 10px 0;
        }
    }
}
.section-primary-light {
    background:rgba(166, 208, 15, 0.12);
}